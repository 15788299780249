import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import PageLayout from '../components/Layout/PageLayout'

const NotFoundPage = ({ location }) => {
  return (
    <PageLayout location={location} title="Error Page" pageTitle="Train Derailment" is404={true}>
      <div className="d-flex align-items-center justify-content-center" style={{ height: '250px' }}>
        <div className="flex-column align-items-center text-center">
          <h2 className="mt-0">404: Not Found</h2>
          <p className="mb-0">You just hit a route that doesn't exist... the sadness.</p>
        </div>
      </div>
    </PageLayout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
